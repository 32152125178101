export const workContent = [
	{
		title: 'Full Stack Web Developer',
		date: 'Feb 2020 - Present',
		responsibility: [
			{
				title:
					'Design responsive and cross browser compatible web applications using CSS framework such as Bootstrap. Proficient in CSS flexbox and grid layout.',
			},
			{
				title:
					'Design relational database (MYSQL). Also proficient in NOSQL database management system such as MongoDB. ',
			},
			{
				title: 'Backend development with LARAVEL.',
			},
			{
				title:
					'REST API design and development. Implement JWT(JSON Web Token) for API authentication.',
			},
			{
				title: 'API testing and documentation with POSTMAN.',
			},
			{
				title:
					"Design Single Page Applications (SPA's) using javascript library REACT. Proficient in Context API and Redux for state management.",
			},
			{
				title: 'REST API design and development using Express JS and MongoDB.',
			},
		],
		company: 'FreeLancer',
	},
	{
		title: 'Web Developer (LARAVEL)',
		date: 'Mar 2018 - Feb 2020',
		responsibility: [
			{
				title: 'Develop business logic, create and maintain database.',
			},
			{
				title: 'Fix bugs, troubleshoot and resolve backend problems.',
			},
			{
				title: 'Deploy web applications.',
			},
		],
		company: 'Code IT Pvt.Ltd',
	},
];

export const academicsContent = [
	{
		title: "Bachelor's Degree in Computer Science & Information Technology",
		date: '2013 - 2017',
		responsibility: [
			{
				title: 'Software Engineering',
			},
			{
				title: 'Object Oriented Programming',
			},
			{
				title: 'Web Development',
			},
			{
				title: 'Advanced Database Management System',
			},
		],
		company: 'Birendra Memorial College, Dharan',
	},
	{
		title: 'High School Degree',
		date: '2009 - 2012',
		responsibility: [
			{
				title: 'Physics',
			},
			{
				title: 'Mathematics',
			},
			{
				title: 'English',
			},
		],
		company: 'NAAST (National Academy of Applied Science & Technology)',
	},
];

export const internshipContent = [
	{
		title: 'Internship on Mobile Application Development',
		date: 'Dec 2017 - Jan 2018',
		responsibility: [
			{
				title: 'React Native',
			},
		],
		company: 'Code IT Pvt.Ltd',
	},
	{
		title:
			'Face Recognition Based Attendance Management System (Final Year Project)',
		date: 'Aug 2017',
		responsibility: [
			{
				title: 'User Interface design using JAVA based framework JavaFX.',
			},
			{
				title:
					'System developed using JAVA programming language and MYSQL as a database management system. System developed under MVC architecture. ',
			},
			{
				title:
					'Implementation of Viola Jones face detection algorithm for real time face detection. ',
			},
			{
				title:
					'Implementation of eigen faces and PCA (Principal Component Analysis) to extract eigen faces. ',
			},
		],
		company: 'Code IT Pvt.Ltd',
	},
	{
		title:
			'Designing Enterprise Network Through Cisco Network Technologies (Bootcamp)',
		date: '2016',
		responsibility: [
			{
				title: 'Router Configuration',
			},
			{
				title: '(NAT) Network Address Translaton',
			},
			{
				title: '(VLAN) Virtual Local Area Network',
			},
		],
		company: 'Birendra Memorial College, Dharan',
	},
];
