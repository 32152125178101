import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SidebarContext from '../../context/sidebar/SidebarContext';
import './modal.css';

const Modal = ({ navbarDark }) => {
	const sidebarContext = useContext(SidebarContext);

	const { showSidebar } = sidebarContext;

	const [sidebar, setSidebar] = useState(false);

	useEffect(() => {
		if (sidebar) {
			showSidebar();
		}
	}, [sidebar]);

	return (
		<div
			className={
				navbarDark
					? 'navbar-modal-links navbar-modal-links-light'
					: 'navbar-modal-links'
			}
		>
			<Link to="/">Intro</Link>

			<Link to="/resume">Resume</Link>

			<Link to="/portfolio">Projects</Link>

			<Link to="/contact">Contact</Link>

			<Link to="#" onClick={() => setSidebar(true)}>
				Skills
			</Link>
		</div>
	);
};

export default Modal;
