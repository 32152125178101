import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './containers/header/header';
import Resume from './containers/resume/Resume';
import Portfolio from './containers/portfolio/Portfolio';
import Contact from './containers/contact/Contact';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import SidebarState from './context/sidebar/SidebarState';

function App() {
	return (
		<SidebarState>
			<Router>
				<Sidebar />
				<Navbar />
				<Routes>
					<Route path="/" element={<Header />} />
					<Route path="/resume" element={<Resume />} />
					<Route path="/portfolio" element={<Portfolio />} />
					<Route path="/contact" element={<Contact />} />
				</Routes>
			</Router>
		</SidebarState>
	);
}

export default App;
