import React from 'react';
import './alert.css';
import {
	MdOutlineMarkEmailRead,
	MdOutlineErrorOutline,
	MdOutlineWifiOff,
} from 'react-icons/md';

const Alert = ({ alertMessage }) => {
	return (
		<div
			className={
				alertMessage.type === 'success'
					? 'alert-container success'
					: alertMessage.type === 'error'
					? 'alert-container error'
					: 'alert-container network_error'
			}
		>
			<div className="alert-icon">
				{alertMessage.type === 'success' ? (
					<MdOutlineMarkEmailRead />
				) : alertMessage.type === 'error' ? (
					<MdOutlineErrorOutline />
				) : (
					<MdOutlineWifiOff />
				)}
			</div>
			<div className="alert-message">{alertMessage.message}</div>
		</div>
	);
};

export default Alert;
