import React from 'react';
import './loader.css';
import loaderImg from '../../assets/images/page-loader.svg';
import loaderMail from '../../assets/images/email-loader.svg';
const Loader = ({ page, pageNumber, loaderDark }) => {
	return (
		<div className={loaderDark ? 'loader loaderDark' : 'loader'}>
			<div className="loader-indicator">
				<img
					src={page === 'sending...' ? loaderMail : loaderImg}
					alt="loader"
				/>
			</div>
			<div className="loader-page ">
				<div className="loader-page-name">{page}</div>
				{pageNumber && <div className="loader-page-number">{pageNumber}</div>}
			</div>
		</div>
	);
};

export default Loader;
