import { SHOW_SIDEBAR, HIDE_SIDEBAR } from '../../constants/types';

export default (state, action) => {
	switch (action.type) {
		case SHOW_SIDEBAR:
			return {
				...state,
				toggleSidebar: true,
			};

		case HIDE_SIDEBAR: {
			return {
				...state,
				toggleSidebar: false,
			};
		}

		default:
			return state;
	}
};
