import React from 'react';
import './modal.css';
import { FaReact, FaLaravel, FaNodeJs, FaGitAlt } from 'react-icons/fa';

const Modal = ({ project }) => {
	return (
		<div className="portfolio-details-modal-container">
			<div className="portfolio-details-modal-title-icon">
				{project.category === 'Laravel' && (
					<FaLaravel
						className="project-category-icon-animation2"
						size={34}
						style={{ color: '#F05340' }}
					/>
				)}
				{project.category === 'React' && (
					<FaReact
						className="project-category-icon-animation1"
						size={34}
						style={{ color: '#61dafb' }}
					/>
				)}
				{project.category === 'MERN' && (
					<FaNodeJs
						className="project-category-icon-animation2"
						size={34}
						style={{ color: '#68A063' }}
					/>
				)}
			</div>
			<div className="portfolio-details-modal-content">
				<div className="portfolio-details-modal-title">{project.title}</div>
				<div className="portfolio-details-modal-description">
					{project.description}
				</div>
				<div className="portfolio-details-modal-btn">
					{project.deploy && (
						<a
							className="button-secondary"
							href={project.deploy}
							target="_blank"
							rel="noopener noreferrer"
						>
							Demo
						</a>
					)}
					<a
						className="button-secondary"
						href={project.git}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaGitAlt size={20} />
						&nbsp; Source
					</a>
				</div>
				<div className="portfolio-details-modal-snapshots">
					{project.images &&
						project.images.map((image, index) => (
							<div className="portfolio-details-modal-snapshot" key={index}>
								<a href={image.link} target="_blank" noopener noreferrer>
									<img src={image.link} alt="snapshot" />
								</a>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default Modal;
