import React, { useState, useEffect } from 'react';
import './portfolio.css';
import { FaLaravel, FaReact, FaNodeJs } from 'react-icons/fa';
import { projects } from '../../assets/data/projects';
import Card from '../../components/portfolio/Card';
import Loader from '../../components/page/Loader';

const Portfolio = () => {
	const [category, setCategory] = useState('All');

	const [loader, setLoader] = useState(true);

	const [filteredProjects, setFilteredProjects] = useState([]);

	const setCategoryHandler = (e) => {
		setCategory(e.target.getAttribute('category'));
	};

	useEffect(() => {
		setTimeout(() => {
			setLoader(false);
		}, 2000);

		getFilteredProjects();

		//eslint-disable-next-line
	}, [category]);

	const getFilteredProjects = () => {
		if (category !== 'All') {
			const filteredProjects = projects.filter(
				(project) => project.category === category
			);

			setFilteredProjects(filteredProjects);
		} else {
			setFilteredProjects(projects);
		}
	};

	return (
		<>
			{loader ? (
				<div className="loader-container-portfolio">
					<Loader page="projects" pageNumber="02" loaderDark={false} />
				</div>
			) : (
				<div className="portfolio section_padding" id="portfolio">
					<div className="portfolio-category-navigation">
						<div
							className={
								category === 'All'
									? 'portfolio-category-all active-all'
									: 'portfolio-category-all'
							}
							category="All"
							onClick={(e) => setCategoryHandler(e)}
						>
							ALL
						</div>
						<div
							className={
								category === 'Laravel'
									? 'portfolio-category-laravel active-laravel'
									: 'portfolio-category-laravel'
							}
							category="Laravel"
							onClick={(e) => setCategoryHandler(e)}
						>
							<FaLaravel />
							LARAVEL
						</div>
						<div
							className={
								category === 'React'
									? 'portfolio-category-react active-react'
									: 'portfolio-category-react'
							}
							category="React"
							onClick={(e) => setCategoryHandler(e)}
						>
							<FaReact />
							REACT
						</div>
						<div
							className={
								category === 'MERN'
									? 'portfolio-category-mern active-mern'
									: 'portfolio-category-mern'
							}
							category="MERN"
							onClick={(e) => setCategoryHandler(e)}
						>
							<FaNodeJs />
							MERN
						</div>
					</div>
					<div className="portfolio-container">
						<Card projects={filteredProjects} category={category} />
					</div>
				</div>
			)}
		</>
	);
};

export default Portfolio;
