import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './header.css';
import headerImage from '../../assets/images/header-side-img.gif';
import { RiDownload2Line, RiMailSendLine } from 'react-icons/ri';
import Loader from '../../components/page/Loader';

const Home = () => {
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoader(false);
		}, 2000);
	}, []);

	return (
		<>
			{loader ? (
				<div className="loader-container-header">
					<Loader page="Intro" pageNumber="00" loaderDark={false} />
				</div>
			) : (
				<div className="header section_padding">
					<div className="header-content">
						<h6 className="text-muted">
							<span className="header-content-intro">Hello, My name is</span>
						</h6>
						<h1>
							<span>Kapil Tamang</span>
						</h1>
						<h5 className="text-muted header-content-designation">
							Full Stack Web Developer
						</h5>
						<p className="header-content-description">
							I <span>Design</span> & <span>Develop </span>
							web applications. Proficient working with CSS framework Bootstrap
							and javascript as a Frontend technology. Proficient in PHP
							framework Laravel with MYSQL database as a Backend technology.
							Recently also working on MERN stack applications.
						</p>
						<div className="header-btn">
							<Link to="/resume" className="button-secondary">
								Resume &nbsp;
								<RiDownload2Line />
							</Link>
							<Link to="/contact" className="button-primary">
								Contact &nbsp;
								<RiMailSendLine />
							</Link>
						</div>
					</div>
					<div className="header-image">
						<img src={headerImage} alt="" />
					</div>
				</div>
			)}
		</>
	);
};

export default Home;
