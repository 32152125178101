import React, { useState, useEffect, useRef } from 'react';
import { MdPlace, MdMailOutline } from 'react-icons/md';
import './contact.css';
import Loader from '../../components/page/Loader';
import emailJs from 'emailjs-com';
import Alert from '../../components/alert/Alert';

const Contact = () => {
	const [loader, setLoader] = useState(true);

	const [mailSendLoading, setMailSendLoading] = useState(false);

	const [showAlert, setShowAlert] = useState(false);

	const [alertMessage, setAlertMessage] = useState({});

	const form = useRef();

	useEffect(() => {
		setTimeout(() => {
			setLoader(false);
		}, 2000);
	}, []);

	useEffect(() => {
		if (Object.keys(alertMessage).length !== 0) {
			setShowAlert(true);
			window.scrollTo(0, 0);
			setTimeout(() => {
				setShowAlert(false);
				setAlertMessage({});
			}, 4000);
		}
	}, [alertMessage]);

	const sendEmailHandler = (e) => {
		e.preventDefault();

		setMailSendLoading(true);

		if (window.navigator.onLine) {
			emailJs
				.sendForm(
					process.env.REACT_APP_SERVICE_ID,
					process.env.REACT_APP_TEMPLATE_ID,
					form.current,
					process.env.REACT_APP_USER_ID
				)
				.then(
					(result) => {
						setMailSendLoading(false);
						setAlertMessage({
							message: 'Email Has Been Sent Successfully',
							type: 'success',
						});
					},
					(error) => {
						setMailSendLoading(false);
						setAlertMessage({
							message: 'Sending Failed. Try Again Later',
							type: 'error',
						});
					}
				);
		} else {
			setMailSendLoading(true);
			setTimeout(() => {
				setMailSendLoading(false);
				setAlertMessage({
					message: 'No Internet Connection. Sending Failed.',
					type: 'network_error',
				});
			}, 3000);
		}
	};

	return (
		<>
			{loader ? (
				<div className="loader-container-contact">
					<Loader page="contact" pageNumber="03" loaderDark={true} />
				</div>
			) : mailSendLoading ? (
				<div className="loader-container-contact">
					<Loader page="sending..." pageNumber="" loaderDark={true} />
				</div>
			) : (
				<div className="contact section_padding" id="contact">
					<div className="contact-container">
						<div className="contact-info">
							<div className="contact-info-heading">
								<span> Hello, </span>there
							</div>
							<div className="contact-info-text">
								I'm Open to work, Please do contact me.
							</div>
							<div className="contact-info-details">
								<ul>
									<li>
										<span>
											<MdMailOutline size={25} />
										</span>
										&nbsp; tamang.kapil11235@gmail.com
									</li>
									<li>
										<span>
											<MdPlace size={28} />
										</span>
										&nbsp; Dharan-16
									</li>
								</ul>
							</div>
						</div>
						<div className="contact-form">
							<div className="contact-form-card">
								<form ref={form} onSubmit={sendEmailHandler}>
									<div className="contact-input">
										<label htmlFor="name">
											Name <span>*</span>
										</label>
										<input type="text" name="name" required />
									</div>
									<div className="contact-input">
										<label htmlFor="name">
											Email <span>*</span>
										</label>
										<input type="email" name="email" required />
									</div>
									<div className="contact-input">
										<label htmlFor="name">
											Message <span>*</span>
										</label>
										<textarea
											name="message"
											cols="30"
											rows="4"
											required
										></textarea>
									</div>
									<div className="contact-submit">
										<button type="submit" value="Submit">
											Send
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					{showAlert && <Alert alertMessage={alertMessage} />}
				</div>
			)}
		</>
	);
};

export default Contact;
