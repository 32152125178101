import React, { useState } from 'react';
import { FaLaravel, FaReact, FaNodeJs } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import Modal from '../../components/portfolio/Modal';
import WordLimit from 'react-word-limit';
import './card.css';

const Card = ({ projects }) => {
	const [modal, setModal] = useState(false);

	const [project, setProject] = useState();

	const setModalHandler = (id) => {
		setModal(!modal);
		const clickedProject = projects.filter((project) => project.id === id);

		setProject(clickedProject[0]);
	};

	return (
		<>
			{projects &&
				projects.map((project) => (
					<>
						<div className="portfolio-card" key={project.id}>
							<div className="portfolio-card-header-icon">
								{project.category === 'Laravel' ? (
									<FaLaravel
										className="project-category-icon-animation2"
										size={22}
										style={{ color: '#F05340' }}
									/>
								) : project.category === 'React' ? (
									<FaReact
										className="project-category-icon-animation1"
										size={22}
										style={{ color: '#61dafb' }}
									/>
								) : (
									<FaNodeJs
										className="project-category-icon-animation2"
										size={22}
										style={{ color: '#68A063' }}
									/>
								)}
							</div>
							<div className="portfolio-card-content">
								<div className="portfolio-card-header">{project.title}</div>
								<div className="portfolio-card-text">
									<WordLimit limit={150}>{project.description}</WordLimit>
								</div>
							</div>
							<div className="portfolio-card-inner-modal">
								<div className="portfolio-card-inner-modal-icon">
									{project.category === 'Laravel' ? (
										<FaLaravel
											className="project-category-icon-animation2"
											size={35}
											style={{ color: '#F05340' }}
										/>
									) : project.category === 'React' ? (
										<FaReact
											className="project-category-icon-animation1"
											size={35}
											style={{ color: '#61dafb' }}
										/>
									) : (
										<FaNodeJs
											className="project-category-icon-animation2"
											size={35}
											style={{ color: '#68A063' }}
										/>
									)}
								</div>
								<div className="portfolio-card-inner-modal-title">
									{project.title}
								</div>
								<div className="portfolio-card-inner-modal-btn">
									<button
										className="card-button"
										onClick={() => setModalHandler(project.id)}
									>
										More...
									</button>
								</div>
							</div>
						</div>
					</>
				))}
			{modal && (
				<div className="project-details-modal project-details-modal-animation section_padding">
					<div className="modal-close">
						<MdClear size={40} onClick={() => setModal(!modal)} />
					</div>
					<Modal project={project} />
				</div>
			)}
		</>
	);
};

export default Card;
