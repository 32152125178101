import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RiMenu4Line } from 'react-icons/ri';
import { MdClear } from 'react-icons/md';
import { BsLayoutSidebarInset } from 'react-icons/bs';
import logo from '../../assets/images/logo.svg';
import logoDark from '../../assets/images/logo-dark.svg';
import './navbar.css';
import SidebarContext from '../../context/sidebar/SidebarContext';
import NavbarModal from './Modal';

const Navbar = () => {
	const location = useLocation();

	const currentURL = location.pathname;

	const sidebarContext = useContext(SidebarContext);

	const { toggleSidebar, showSidebar } = sidebarContext;

	const [sidebar, setSidebar] = useState(false);

	const [navbarDark, setNavbarDark] = useState(false);

	const [navbarModal, setNavbarModal] = useState(false);

	useEffect(() => {
		if (navbarModal) {
			setNavbarModal(false);
		}

		if (sidebar) {
			showSidebar();
		}

		if (!toggleSidebar) {
			setSidebar(false);
		}

		if (location.pathname === '/resume' || location.pathname === '/contact') {
			setNavbarDark(true);
		} else {
			setNavbarDark(false);
		}

		//eslint-disable-next-line
	}, [sidebar, toggleSidebar, location]);

	const handleToggleSidebar = () => {
		setSidebar(true);
	};

	return (
		<>
			<nav className={navbarDark ? 'navbar-dark' : ''}>
				<div className="navbar-logo">
					<Link to="/">
						{navbarDark ? (
							<img src={logoDark} alt="logo" />
						) : (
							<img src={logo} alt="logo" />
						)}
					</Link>
				</div>
				<div className="navbar-navigation">
					<div className="navbar-links">
						<div>
							<li
								className={currentURL === '/resume' ? 'navbar-link-active' : ''}
							>
								<Link to="/resume">
									<span className="page-no">01.</span>Resume
								</Link>
							</li>
							<li
								className={
									currentURL === '/portfolio' ? 'navbar-link-active' : ''
								}
							>
								<Link to="/portfolio">
									<span className="page-no">02.</span>Projects
								</Link>
							</li>
							<li
								className={
									currentURL === '/contact' ? 'navbar-link-active' : ''
								}
							>
								<Link to="/contact">
									<span className="page-no">03.</span>Contact
								</Link>
							</li>
						</div>
					</div>
					<div className="navbar-icon-sidebar">
						{toggleSidebar ? (
							<MdClear className="navbar-icon-close" size={32} />
						) : (
							<BsLayoutSidebarInset
								className="navbar-icon-menu"
								size={32}
								onClick={() => handleToggleSidebar()}
							/>
						)}
					</div>
					<div className="navbar-icon-all">
						{toggleSidebar ? (
							<MdClear className="navbar-icon-close" size={32} />
						) : (
							<RiMenu4Line
								className="navbar-icon-menu"
								size={32}
								onClick={() => setNavbarModal(true)}
							/>
						)}
					</div>
				</div>
				{navbarModal && (
					<div
						className={
							navbarDark
								? 'navbar-modal-container scale-in-hor-center navbar-modal-light'
								: 'navbar-modal-container scale-in-hor-center'
						}
					>
						<div className="navbar-modal-close">
							<MdClear size={30} onClick={() => setNavbarModal(false)} />
						</div>
						<NavbarModal navbarDark={navbarDark} />
					</div>
				)}
			</nav>
		</>
	);
};

export default Navbar;
