export const projects = [
	{
		id: '1',
		title: 'Multi User Blogging System',
		description:
			'A simple multiple user login & blogging system. User can create an account and post a blog, comment on blogs.',
		images: [],
		git: 'https://github.com/KapilTamang/multi-user-blogging-system',
		deploy: '',
		category: 'Laravel',
	},
	{
		id: '2',
		title: 'Restful API For Buying & Selling System',
		description: 'A restful API for multivendor system.',
		images: [],
		git: 'https://github.com/KapilTamang/restful-api-for-buying-and-selling-system',
		deploy: '',
		category: 'Laravel',
	},
	{
		id: '3',
		title: 'Netflix Clone App',
		description:
			' Netflix clone app build with React using styled components and context API. It is a frontend app with user authentication using Firebase.',
		images: [
			{
				link: 'https://user-images.githubusercontent.com/70515260/132942067-a6104a6a-6647-4b30-a2fe-385ef44cd248.png',
			},
			{
				link: 'https://user-images.githubusercontent.com/70515260/132942072-2fd8b93c-e3f9-441d-b00a-ff0a5bd982c4.png',
			},
			{
				link: 'https://user-images.githubusercontent.com/70515260/132942092-e778f476-b4ec-413a-b7f8-4d12257f3215.png',
			},
		],
		git: 'https://github.com/KapilTamang/netflix-clone-react',
		deploy: 'https://netflixx-clone-react.netlify.app/',
		category: 'React',
	},
	{
		id: '4',
		title: 'Git User Finder App',
		description:
			'Git-User Finder App is a react application for searching github users. It allows users to search github user, explore their public repos.',
		images: [
			{
				link: 'https://firebasestorage.googleapis.com/v0/b/mypersonalsite-160fa.appspot.com/o/Git%20User%20Finder%20App%20React%2FGithub-Finder-App-User.png?alt=media&token=7518ce71-ecca-4658-9fb0-d102ff6704cc',
			},
			{
				link: 'https://firebasestorage.googleapis.com/v0/b/mypersonalsite-160fa.appspot.com/o/Git%20User%20Finder%20App%20React%2FGithub-Finder-App-Search.png?alt=media&token=03bf9471-a272-4c2d-8d41-f868aa90702c',
			},
			{
				link: 'https://firebasestorage.googleapis.com/v0/b/mypersonalsite-160fa.appspot.com/o/Git%20User%20Finder%20App%20React%2FGithub-Finder-App.png?alt=media&token=e8db3d56-9ce0-4808-ad7e-c25830ef9071',
			},
		],
		git: 'https://github.com/KapilTamang/react-gituser-finder-app',
		deploy: 'https://react-gituser-finder-kapil.netlify.app/',
		category: 'React',
	},
	{
		id: '5',
		title: 'Hotel Rooms App',
		description:
			'Hotel Rooms App is a simple react application for browsing hotel rooms. It allows user to browse rooms and filter rooms based on type, capacity, price etc ',
		images: [
			{
				link: 'https://firebasestorage.googleapis.com/v0/b/mypersonalsite-160fa.appspot.com/o/Hotel%20Rooms%20App%20React%2FHotel-Rooms-App-Home.png?alt=media&token=7363501a-3b0d-4437-874d-69cf14400c7b',
			},
			{
				link: 'https://firebasestorage.googleapis.com/v0/b/mypersonalsite-160fa.appspot.com/o/Hotel%20Rooms%20App%20React%2FHotel-Rooms-App-Rooms.png?alt=media&token=6f308db5-b424-47a3-bbe9-25b7c9279d24',
			},
			{
				link: 'https://firebasestorage.googleapis.com/v0/b/mypersonalsite-160fa.appspot.com/o/Hotel%20Rooms%20App%20React%2FHotel-Rooms-App.png?alt=media&token=61eeaedd-361f-4485-a8ff-7d5352a168a9',
			},
		],
		git: 'https://github.com/KapilTamang/react-hotel-rooms-app',
		deploy: 'https://react-hotel-rooms-kapil.netlify.app/',
		category: 'React',
	},
	{
		id: '6',
		title: 'Ecommerce MERN Application',
		description:
			'Ecommerce application build using a MERN stack. The app includes functionalities such as search, pagination, email verification and integration of Stripe as a payment gateway.',
		images: [
			{
				link: 'https://user-images.githubusercontent.com/70515260/132940809-20ce427b-e36e-40dc-933c-e10cb7a80bd8.png',
			},
			{
				link: 'https://user-images.githubusercontent.com/70515260/133555820-69f40f0c-28f1-40d9-868f-42f6deead206.png',
			},
			{
				link: 'https://user-images.githubusercontent.com/70515260/132940811-61165b88-90bf-4663-81d0-41f864721233.png',
			},
			{
				link: 'https://user-images.githubusercontent.com/70515260/132941005-2c263192-eb25-4beb-a9d7-1ded34298a18.png',
			},
		],
		git: 'https://github.com/KapilTamang/Ecommerce-MERN-App',
		deploy: 'https://ecommerce-mern-app-afnaipasal.herokuapp.com/',
		category: 'MERN',
	},
];
