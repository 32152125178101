import React, { useState, useEffect } from 'react';
import './resume.css';
import ResumeCard from '../../components/resume/Card';
import {
	workContent,
	academicsContent,
	internshipContent,
} from '../../assets/data/resume';
import Loader from '../../components/page/Loader';

const Resume = () => {
	const [loader, setLoader] = useState(true);

	const [category, setCategory] = useState('work');

	const setCategoryHandler = (e) => {
		setCategory(e.target.getAttribute('category'));
	};

	useEffect(() => {
		setTimeout(() => {
			setLoader(false);
		}, 2000);

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [category]);

	return (
		<>
			{loader ? (
				<div className="loader-container-resume">
					<Loader page="resume" pageNumber="01" loaderDark={true} />
				</div>
			) : (
				<div className="resume section_padding" id="resume">
					<div className="resume-container">
						<div className="resume-title ">
							<div className="resume-title-list">
								<li
									className={
										category === 'work'
											? 'resume-title-work active'
											: 'resume-title-work'
									}
									category="work"
									onClick={(e) => setCategoryHandler(e)}
								>
									Experience
								</li>
								<li
									className={
										category === 'internship'
											? 'resume-title-internship active'
											: 'resume-title-internship'
									}
									category="internship"
									onClick={(e) => setCategoryHandler(e)}
								>
									Internship & Training
								</li>
								<li
									className={
										category === 'academics'
											? 'resume-title-academics active'
											: 'resume-title-academics'
									}
									category="academics"
									onClick={(e) => setCategoryHandler(e)}
								>
									Academics
								</li>
							</div>
						</div>
						<div className="resume-title-container"></div>
						<div className="resume-content">
							{category === 'work' && (
								<ResumeCard contents={workContent} title="Work" />
							)}
							{category === 'academics' && (
								<ResumeCard contents={academicsContent} title="Academics" />
							)}
							{category === 'internship' && (
								<ResumeCard
									contents={internshipContent}
									title="Internship & Training"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Resume;
