import React, { useState, useEffect, useRef, useContext } from 'react';
import './sidebar.css';
import {
	RiLinkedinBoxFill,
	RiGithubFill,
	RiFacebookBoxFill,
	RiTwitterFill,
} from 'react-icons/ri';
import { MdClear } from 'react-icons/md';
import myImage from '../../assets/images/sanam.jpg';
import SidebarContext from '../../context/sidebar/SidebarContext';

const Sidebar = () => {
	const sidebarRef = useRef();

	const sidebarContext = useContext(SidebarContext);

	const { toggleSidebar, hideSidebar } = sidebarContext;

	const [animation, setAnimation] = useState(0);

	const outsideSidebarClick = (event) => {
		if (!sidebarRef.current.contains(event.target) && toggleSidebar) {
			hideSidebar();
		}
	};

	useEffect(() => {
		if (toggleSidebar) {
			languageCounterAnimation();
			setAnimation(1);
		} else {
			setAnimation(0);
		}

		document.addEventListener('mousedown', outsideSidebarClick);

		return () => {
			document.removeEventListener('mousedown', outsideSidebarClick);
		};

		//eslint-diable-next-line
	}, [toggleSidebar, setAnimation]);

	//Close sidebar for small devices
	const handleCloseSidebar = () => {
		hideSidebar();
	};

	//Language counter animation
	const languageCounterAnimation = () => {
		const number1 = document.querySelector('.counter-1');
		const number2 = document.querySelector('.counter-2');
		const number3 = document.querySelector('.counter-3');
		let counter1 = 0;
		let counter2 = 0;
		let counter3 = 0;

		setInterval(() => {
			if (counter1 === 96) {
				clearInterval();
			} else {
				counter1 += 1;
				number1.textContent = counter1 + '%';
			}

			if (counter2 === 80) {
				clearInterval();
			} else {
				counter2 += 1;
				number2.textContent = counter2 + '%';
			}

			if (counter3 === 5) {
				clearInterval();
			} else {
				counter3 += 1;
				number3.textContent = counter3 + '%';
			}
		}, 12);
	};

	return (
		<div
			ref={sidebarRef}
			className={
				toggleSidebar
					? 'sidebar slide-right shadow active'
					: 'sidebar slide-right'
			}
		>
			<div className="sidebar-close">
				{toggleSidebar && (
					<MdClear size={30} onClick={() => handleCloseSidebar()} />
				)}
			</div>
			<div className="sidebar-profile pt-4 pb-2">
				<div
					className="
				social-links
				d-flex
				justify-content-center
				flex-column
				align-items-center
			"
				>
					<div className="vertical-line"></div>
					<ul>
						<li>
							<a
								href="https://linkedin.com/in/kapil-tamang-26a33518b/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<RiLinkedinBoxFill className="social-icon" size={28} />
							</a>
						</li>
						<li>
							<a
								href="https://github.com/KapilTamang"
								target="_blank"
								rel="noopener noreferrer"
							>
								<RiGithubFill className="social-icon" size={28} />
							</a>
						</li>
						<li>
							<a
								href="https://twitter.com/?lang=en"
								target="_blank"
								rel="noopener noreferrer"
							>
								<RiTwitterFill className="social-icon" size={28} />
							</a>
						</li>
						<li>
							<a
								href="https://www.facebook.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<RiFacebookBoxFill className="social-icon" size={28} />
							</a>
						</li>
					</ul>
					<div className="vertical-line"></div>
				</div>
				<img
					className="sidebar-profile-img img-fluid mb-3 shadow"
					src={myImage}
					alt=""
				/>

				<p className="sidebar-profile-name">Kapil Tamang</p>
				<p className="sidebar-profile-description text-muted">
					Full Stack Web Developer
				</p>
			</div>
			<div className="sidebar-language-container">
				<div className="sidebar-language">
					<svg>
						<circle cx="80" cy="80" r="30" animation={animation}></circle>
					</svg>
					<div className="counter-1"></div>
					<h6>Nepali</h6>
				</div>
				<div className="sidebar-language">
					<svg>
						<circle cx="80" cy="80" r="30" animation={animation}></circle>
					</svg>
					<div className="counter-2"></div>
					<h6>English</h6>
				</div>
				<div className="sidebar-language">
					<svg>
						<circle cx="80" cy="80" r="30" animation={animation}></circle>
					</svg>
					<div className="counter-3"></div>
					<h6>Others</h6>
				</div>
			</div>
			<div className="row sidebar-skills">
				<div className="row sidebar-skills-language">
					<div className="col-3 language">
						<p className="text-end">Html</p>
					</div>
					<div className="col-8 me-2 measure">
						<div className="progress">
							<div
								className="progress-bar"
								animation={animation}
								role="progressbar"
								style={{ width: '95%' }}
								aria-valuenow="95"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								<span>95%</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row sidebar-skills-language">
					<div className="col-3 language">
						<p className="text-end">Css</p>
					</div>
					<div className="col-8 me-2 measure">
						<div className="progress">
							<div
								className="progress-bar"
								animation={animation}
								role="progressbar"
								style={{ width: '80%' }}
								aria-valuenow="80"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								80%
							</div>
						</div>
					</div>
				</div>
				<div className="row sidebar-skills-language">
					<div className="col-3 language">
						<p className="text-end">JS</p>
					</div>
					<div className="col-8 me-2 measure">
						<div className="progress">
							<div
								className="progress-bar"
								role="progressbar"
								animation={animation}
								style={{ width: '70%' }}
								aria-valuenow="70"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								70%
							</div>
						</div>
					</div>
				</div>
				<div className="row sidebar-skills-language">
					<div className="col-3 language">
						<p className="text-end">Php</p>
					</div>
					<div className="col-8 me-2 measure">
						<div className="progress">
							<div
								className="progress-bar"
								role="progressbar"
								animation={animation}
								style={{ width: '80%' }}
								aria-valuenow="80"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								80%
							</div>
						</div>
					</div>
				</div>
				<div className="row sidebar-skills-language">
					<div className="col-3 language">
						<p className="text-end">Laravel</p>
					</div>
					<div className="col-8 me-2 measure">
						<div className="progress">
							<div
								className="progress-bar"
								animation={animation}
								role="progressbar"
								style={{ width: '75%' }}
								aria-valuenow="75"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								75%
							</div>
						</div>
					</div>
				</div>
				<div className="row sidebar-skills-language">
					<div className="col-3 language">
						<p className="text-end">React</p>
					</div>
					<div className="col-8 me-2 measure">
						<div className="progress">
							<div
								className="progress-bar"
								animation={animation}
								role="progressbar"
								style={{ width: '70%' }}
								aria-valuenow="70"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								70%
							</div>
						</div>
					</div>
				</div>
				<div className="row sidebar-skills-language">
					<div className="col-3 language">
						<p className="text-end">Node</p>
					</div>
					<div className="col-8 me-2 measure">
						<div className="progress">
							<div
								className="progress-bar"
								animation={animation}
								role="progressbar"
								style={{ width: '60%' }}
								aria-valuenow="70"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								60%
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
