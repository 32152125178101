import React from 'react';
import './card.css';
import { RiComputerLine, RiBookOpenFill } from 'react-icons/ri';

const resume = ({ contents, title }) => {
	return (
		<div className="resume-card">
			<div className="card-heading">{title}</div>
			<div className="line"></div>
			<div className="card-content-container">
				{contents &&
					contents.map((content, index) => (
						<div className="card-content" key={index}>
							<div className="card-content-title">{content.title}</div>
							<div className="card-content-date">{content.date}</div>
							<div className="card-content-responsibility">
								{content.responsibility &&
									content.responsibility.map((data, index) => (
										<div className="responsibility-list" key={index}>
											{title && title === 'Academics' ? (
												<div className="responsibility-list-icon">
													<RiBookOpenFill />
												</div>
											) : (
												<div className="responsibility-list-icon">
													<RiComputerLine />
												</div>
											)}
											<div className="responsibility-list-content">
												{data.title}
											</div>
										</div>
									))}
							</div>
							<div className="card-content-company">{content.company}</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default resume;
