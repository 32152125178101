import React, { useReducer } from 'react';
import { SHOW_SIDEBAR, HIDE_SIDEBAR } from '../../constants/types';
import SidebarContext from './SidebarContext';
import SidebarReducer from './SidebarReducer';

const SidebarState = (props) => {
	const initialState = {
		toggleSidebar: false,
	};

	const [state, dispatch] = useReducer(SidebarReducer, initialState);

	//Set Toggle Sidebar
	const showSidebar = () => {
		dispatch({
			type: SHOW_SIDEBAR,
		});
	};

	const hideSidebar = () => {
		dispatch({
			type: HIDE_SIDEBAR,
		});
	};

	return (
		<SidebarContext.Provider
			value={{
				toggleSidebar: state.toggleSidebar,
				showSidebar,
				hideSidebar,
			}}
		>
			{props.children}
		</SidebarContext.Provider>
	);
};

export default SidebarState;
